.root {
	position: absolute;
	top: 1.33rem;
	right: 1rem;

	&.loading {
		padding-right: 1rem;
		opacity: 0.4;
	}

	a {
		text-decoration: none !important;
	}

	:global(.SimpleBadge-root) {
		font-size: 1rem;
		color: white;

		&.active {
			background: green;
		}

		&.completed {
			background: grey;
		}
	}

}