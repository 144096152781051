@import 'shared/styles/common.scss';

.root {
	position: absolute;
	right: 0;
	top: 3.5rem;
	bottom: .5rem;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	z-index: 999;
	pointer-events: all;

	// background-color: red;

	.letter {
		padding: .125rem 1rem;

		background: rgba(0,0,0,.125);
		border-radius: .25rem 0 0 .25rem;


		&.activeLetter {
			background: $highlight-color;
			padding:.75rem 1rem;
			color: white;
		}
	}
}
