.root {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: 0 auto;

	.partnerBrand {
		height: 3rem;
	}

	.vayaBrand {
		position: absolute;
		right: -.75rem;
		bottom: -.75rem;

		max-height: 1.5rem;
		border-radius: .25rem;
	}

	.vayaBrandSolo {
		height: 7rem;
		border-radius: .5rem;
	}

	&.small {
		.partnerBrand, .vayaBrandSolo {
			height: 2rem;
		}

		.vayaBrand {
			max-height: 1rem;
			right: -.625rem;
			bottom: .25rem;
		}
	}
}