@import 'shared/styles/common.scss';

.root {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;



	// background: rgba(0,0,0,0.5);
	background: white;
	
	// --content-color: white;
	--content-color: black;

	&.darkMode {
		--content-color: white;
		background: rgba(0,0,0,0.5);
	}

	color: var(--content-color);

	.chooseUser,
	.pickupLocation,
	.dropoffLocation,
	.chooseTime,
	.quoteDisplay,
	.ctaButtons {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		// width: 100%;
		width: 400px;

		max-width: calc(100vw - 3rem);
	}

	.chooseUser,
	.pickupLocation,
	.dropoffLocation,
	.chooseTime {
		padding: .75rem 0rem 0;

		position: relative;

		.swapButton {
			background: white;
			position: absolute;
			top: -1.125rem;
			right: -1.125rem;
			// left: 50%;
			// transform: translateX(-50%);;
		}
		

		:global(.MuiFormControl-root) {
			width: 100%;

			height: 3.5rem;

			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: flex-end;

			// background: rgba(0,0,0,0.125);
			background: rgba(0, 0, 0, 0.09);
			// padding: .35rem 0 .625rem;
			border-radius: .25rem;

			// width: calc(100% - 2rem);
						
			// background: $highlight-color;
			// background: transparentize($highlight-color, 0.75);
			border-radius: .25rem;

			:global(.MuiButtonBase-root) {
				width: 100%;
				height: 100%;

				// display: flex;
				// flex-direction: row;
				// justify-content: center;
				// align-items: flex-end;

				:global(.MuiFormControlLabel-root) {
					margin: .25rem 0 0;
					// vertical-align: baseline;
					width: 100%;
					text-align: center;
					display: block;

					@include force-word-break();
				}
			}

			:global(.MuiFilledInput-root) {
				width: 100%;

				&, &:focus, &:active, &:hover, &:global(.Mui-focused) {
					background: transparent;
				}


				select {
					text-align: center;
					padding-left: 1.5rem;
					padding-bottom: 1rem;
					height: 100%;
				}

				:global(.MuiSelect-icon) {
					display: none;
				}
			}

			:global(.MuiInputBase-root) {
				border-radius: .25rem;
			}

			:global(.MuiInputLabel-formControl) {
				top: .5rem;
				left: .75rem;

				color: $highlight-color;

				&:global(.Mui-focused) {
					// color: white;
					color: $highlight-color;
				}
			}

			select {
				color: var(--content-color);
			}
		}
	}

	&.chooseTime {
		// padding: 0;
	}

	&.darkMode {
		:global(.MuiFormControl-root) {
			// background: $highlight-color;
			background: transparentize($highlight-color, 0.75);

			:global(.MuiInputLabel-formControl) {
				color: rgba(255,255,255,0.75);

				&:global(.Mui-focused) {
					color: white;
				}
			}
		}
	}

	.quoteDisplay {
		padding: .75rem 0;

		.wrap {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: flex-end;

			// background: rgba(0,0,0,0.125);
			background: rgba(0, 0, 0, 0.09);
			// padding: .35rem 0 .625rem;
			border-radius: .25rem;

			width: calc(100% - 0rem);
		}

		.emptyQuoteState {
			min-height: 3.5rem;
			padding: 1rem 2rem;

			.title {
				font-weight: normal;
				font-size: .85rem;
				padding: 0;
			}
		}

		.cannotDispatch {
			min-height: 3.5rem;
			padding: 1rem 2rem;
			background: transparentize($pinkBg, .825);

			.title {
				color: $highlight-color;
				font-weight: bold;
				font-size: .85rem;
				padding: 0;
			}
		}

		// background: $highlight-color;

		h1, h3 {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			// margin: 0 1rem;
			margin: 0;

			text-align: center;
			color: $highlight-color;

			padding: .35rem 1rem .625rem;

			label {
				font-size: .625rem;
			}
		}

		h1 {
			flex: 1;
			// min-width: 8rem;
			white-space: nowrap;

			font-size: 2.2rem;
			font-weight: bold;

			.suffix {
				font-size: 1.2rem;
				// opacity: .5;
			}

			// background: $highlight-color;
			// color: white;
			// padding: .35rem 1rem .625rem;

			// background: red;

			// color: $highlight-color;
		}

		h3 {
			// min-width: 5rem;
			color: rgba(0,0,0,0.625);
		}

		label {
			opacity: 0.625;
		}
	}

	&.darkMode {
		.quoteDisplay {
			.wrap {
				background: transparentize($highlight-color, 0.75);
			}

			h1 {
				color: white;
			}

			h3 {
				color: rgba(255,255,255,0.4);
			}
		}
	}

	.ctaButtons {
		padding: 1.25rem .5rem 0;

		:global(.AppButton-root) {
			padding: .9rem 1rem;

			height: 3.5rem;

			svg {
				margin-right: .5rem;
			}
		}

		:global(.AppButton-root):first-child {
			border-radius: .25rem 0 0 .25rem;
		}

		:global(.AppButton-root):last-child {
			border-radius: 0 .25rem .25rem 0;
		}
	}

	&.darkMode {
		$content-color: white;

		:global(.MuiFormLabel-root),
		:global(.MuiSelect-icon) {
			// color: rgba(255,255,255,0.54) !important;
			color: transparentize($content-color, 0.44);
		}

		:global(.MuiInput-root),
		:global(.MuiIconButton-root),
		:global(.MuiButton-root) {
			color: $content-color !important;
		}

		:global(.MuiIconButton-root):global(.MuiButton-textPrimary),
		:global(.MuiButton-root):global(.MuiButton-textPrimary) {
			color: darken($highlight-color, 1%) !important;
		}

		:global(.MuiInput-underline):hover:not(:global(.Mui-disabled)):before,
		:global(.MuiInput-underline:before) {
			// border-color: rgba(255,255,255,0.47) !important;
			border-color: transparentize($content-color, 0.53) !important;
		}

		:global(.MuiTypography-colorTextSecondary) {
			// color: rgba(255,255,255,0.77) !important;
			color: transparentize($content-color, 0.33) !important;
		}
	}
}