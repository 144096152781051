@import 'shared/styles/common.scss';

.root {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	--background: white;
	--content-color: black;

	
	&.darkMode {
		--content-color: white;
		--background: rgba(0,0,0,0.5);
	}

	background: var(--background);
	color: var(--content-color);
	
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: auto;

	width: 100%;
	height: 100%;

	* { 
		box-sizing: border-box;
	}

	// padding: 1rem;

	:global(.MuiAppBar-root) {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;

		.iconButton {
			opacity: 1;
		}

		.hiddenButton {
			opacity: 0;
			pointer-events: none;
		}
	}

	.zoomControl {
		top: 1px;
		// raise above app bar
		z-index: 5000;
		svg {
			color: black;
		}
	}


	// // overflow-x: hidden;

	// color: $content-color;

	// padding-top: 2.5rem;
	// width: 100%;

	.map {
		width: 100%;
		overflow: hidden;
		height: 300px;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.mapContainer {
		position: relative;
		height: 300px;
		width: 100%;

		// box-shadow: 0 0 6px black;

		:global(.TripPath-label) {
			font-size: .75rem  !important;
			text-shadow: 0 0 2px white,
				0 0 3px white,
				0 0 5px white,
				0 0 8px white,
				0 0 10px white,
				0 0 12px white;
			color: $highlight-color !important;
		}
	}

	.statusLine {
		list-style: none;
		display: flex;
		justify-content: center;
		align-items: center;

		:global(.SimpleBadge-root) {
			margin: -1.25rem auto .5rem;
		}
	}


	.tripStops {
		width: 100%;
		padding: .75rem 1rem .25rem 1rem;
		max-width: 375px;
		margin: 0 auto;

		// h2 {
		// 	margin: 0.5rem 0;
		// }


		ul {
			margin: 0;
			padding: 0;
		}
	}

	.comments {
		font-size: .625rem;
		font-weight: normal;
		opacity: .9;
		margin-bottom: .25rem;
	}
	
	.timeTotal {
		margin-bottom: .25rem;
	}

	.timeBreakdown {
		width: 8rem;
		font-size: .5rem;
		font-weight: normal;
		opacity: .9;

		margin-left: calc(-1 * (8rem - 3.5rem))
	}
}

body:global(.native) {
	.root {
		padding-top: calc(var(--native-top-adjust) + 2.5rem);
	}
}
