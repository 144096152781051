@import 'shared/styles/common.scss';

.root {
	* {
		box-sizing: border-box;
	}

	:global(.MuiDialog-paper) {
		min-height: 75vh;
		min-width: 50vw;
	}

	.searchRoot {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 1000;

		box-shadow: 0 0 .75rem rgba(0,0,0,.75),
			0 0 1.25rem rgba(0,0,0,.5);

		// padding: 2px 4px;
		display: flex;
		align-items: center;
		flex-direction: row;
		// width: 400;
		width: 100%;
		// width: calc(100% - 1rem);

		border-radius: 0;
	
		:global(.MuiInputBase-root) {
			width: 100%;
			padding-left: .125rem;
		
			input {
				// margin-left: .5rem;
				flex: 1;

				&::placeholder {
					font-size: 0.825rem;
					// letter-spacing: -1px;
				}
			}
		}

		.iconButton {
			padding: 10px;
		}

		// .divider {
		// 	height: 28px;
		// 	margin: 4px;
		// }
	}

	// from .isPlaceState - tbd if we want these
	.searchRoot {
		box-shadow: 0 0 .75rem rgba(0,0,0,.5),
			0 0 1.25rem rgba(0,0,0,.25);

		.backButton {
			margin-left: .25rem;
		}
	
		:global(.MuiInputBase-root) {
			padding-left: .5rem;
		}
	}


	:global(.MuiList-root) {
		padding-top: 3.25rem;
		width: 100%;
		// padding-left: .4rem;

		width: 100%;
		flex: 1;
		overflow: auto;
		touch-action: pan-y;
		// margin-top: .25rem;
		
		&.showAlphaTabs {
			padding-right: 2.5rem;
		}

	
		:global(.MuiListItem-root):global(.MuiListItem-button) {
			padding-top: .33rem;
			padding-bottom: .33rem;
			padding-left: .25rem;
		}

		:global(.MuiListItemText-multiline) {
			margin: 0;
		}

		
		:global(.MuiTypography-root):global(.MuiListItemText-primary) {
			// margin-top: -.1rem;
			// font-size: 0.75rem;
			line-height: 1.1;
		}
	
		:global(.MuiTypography-root):global(.MuiTypography-colorTextSecondary) {
			// margin-top: -.1rem;
			font-size: 0.75rem;
			line-height: 1.2;
		}

		:global(.MuiListItemIcon-root) {
			color: $highlight-color;
			margin-left: .6rem;
			min-width: 2.125rem;
		}

		:global(.MuiListItemSecondaryAction-root) {
			padding-right: .25rem;				
		}

		:global(.MuiListItemIcon-root),
		:global(.MuiListItemSecondaryAction-root) {
			svg {
				font-size: 1.25rem;
			}
		}
	}


}