@import 'shared/styles/common.scss';

.root {
	.loginButton {
		text-align: center;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		a {
			text-decoration: none;
			display: block;
			margin: .5rem;
		}
	}

	.loginForm {
		.loginError {
			display: block;
			text-align: center;
			color: red;
			margin: 0rem  0 1rem;
			// word-break: break-all;
			@include force-word-break();
		}

		.acceptInviteInfo {
			margin: 0 0 1rem;
			background: transparentize($highlight-color, .9);
			color: black;
			border-radius: .25rem;
			padding: 1rem;
		}


		:global(.MuiFormControl-root) {
			width: 100%;
			margin-bottom: .5rem;
						
			// background: $highlight-color;
			// background: transparentize($highlight-color, 0.75);
			border-radius: .25rem;

			:global(.MuiInputBase-root) {
				border-radius: .25rem;
			}

			:global(.MuiInputLabel-formControl) {
				// top: .5rem;
				// left: .75rem;

				color: $highlight-color;

				&:global(.Mui-focused) {
					// color: white;
					color: $highlight-color;
				}
			}

		}

		.disclaimer {
			width: 100%;
			margin: .125rem 0rem -.5rem;
			font-size: .5rem;
			opacity: 0.65;
			font-weight: normal;
			// max-width: calc(100% - 2rem);
			text-align: center;

			a {
				color: var(--content-color);;
				font-weight: bold;
			}
		}
	}
}


