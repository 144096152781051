@import 'shared/styles/common.scss';

html {
	--drawer-nav-width: 240px;
}

:global(.MuiDrawer-root) {
	z-index: 600001 !important;
}

.drawer {
	@include media('>small') {
		width: var(--drawer-nav-width, 240px);
		flex-shrink: 0;
	}

	.drawerPaper {
		width: var(--drawer-nav-width, 240px);
	}

	a:global(.MuiListItem-root) {
		&:hover {
			color: white;
		}
	}
}

.drawerContent {
	.toolbar {
		min-height: 56px;

		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;

		@include media('>small') {
			min-height: 64px;
		}

		// :global(.SimpleBanner-root) {
		// 	width: 90%;
		// 	margin: 1rem 1rem .4rem;
		// 	img {
		// 		width: 100%;
		// 	}
		// }

		.logo {
			border-radius: .25rem;
			height: 72px;
			margin: 1rem 1rem .5rem;
		}
	}

	// :global(.MuiListItem-root) {
	// 	color: rgba(255,255,255,0.8);

	// 	// padding-top: 0;

	// 	:global(.MuiSvgIcon-root) {
	// 		font-size: 1.25rem;
	// 		color: rgba(255,255,255,0.8);
	// 	}

	// 	:global(.MuiTypography-root) {
	// 		font-size: .8rem;
	// 	}

	// 	&:hover, &:active, &:focus {
	// 		color: white;

	// 		:global(.MuiSvgIcon-root) {
	// 			color: white;
	// 		}
	// 	}
	// }

	:global(.MuiDivider-root) {
		margin-bottom: 8px;
	}
}

body:global(.native) {
	.drawerContent {
		padding-top: var(--native-top-adjust);
	}
}