@import 'shared/styles/common.scss';

.root {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	--background: white;
	--content-color: black;

	
	&.darkMode {
		--content-color: white;
		--background: rgba(0,0,0,0.5);
	}

	background: var(--background);
	color: var(--content-color);
	
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: auto;

	width: 100%;
	height: 100%;

	* { 
		box-sizing: border-box;
	}

	// padding: 1rem;

	.appBar {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;

		:global(.MuiTabs-root) {
			flex: 1;
			width: 100%;
		}

		.hiddenButton {
			opacity: 0;
			pointer-events: none;
		}
	}

	div[role=tabpanel] {
		display: flex;
		flex-direction: column;
		flex: 1;
		position: absolute;
		overflow: auto;

		top: 3rem;
		bottom: 0;
		right: 0;
		left: 0;

		opacity: 1;
		transition: opacity .45s linear;

		&[hidden] {
			opacity: 0;
			pointer-events: none;
		}
	}

	:global(.MuiList-root) {
		// padding-top: 3.625rem;
		width: 100%;
		// padding-left: .4rem;

		width: 100%;
		flex: 1;
		overflow: auto;
		touch-action: pan-y;
		// margin-top: .25rem;d

		:global(.SimpleBadge-root) {
			// color: white;
			font-size: 0.625rem;
			margin-right: .5rem;
			margin-left: -.35rem;
		}

	
		:global(.MuiListItem-root):global(.MuiListItem-button) {
			padding-top: .45rem;
			padding-bottom: .45rem;
		}

		:global(.MuiListItemText-multiline) {
			margin: 0;
		}

		
		:global(.MuiTypography-root):global(.MuiListItemText-primary) {
			// margin-top: -.1rem;
			// font-size: 0.75rem;
			line-height: 1.1;
		}
	
		:global(.MuiTypography-root):global(.MuiTypography-colorTextSecondary) {
			// margin-top: -.1rem;
			font-size: 0.75rem;
			line-height: 1.2;
		}

		:global(.MuiListItemIcon-root) {
			color: $highlight-color;
			margin-left: 0rem;
			min-width: 2.125rem;
		}

		:global(.MuiListItemSecondaryAction-root) {
			padding-right: .25rem;				
		}

		:global(.MuiListItemIcon-root),
		:global(.MuiListItemSecondaryAction-root) {
			svg {
				font-size: 1.25rem;
			}
		}
	}
}