@import 'shared/styles/common.scss';

.root {
	position: absolute;
	top: .5rem;
	// left: calc(0.5rem + var(--drawer-nav-permanent-width, 0px));
	left: .5rem;
	z-index: 110;
	
	opacity: 0;
	pointer-events: none;
	transition: opacity .25s linear;

	:global(.MuiIconButton-root) {
		// background: rgba(0,0,0,.25);
	}
	
	@include media('<small') {
		pointer-events: all;
		opacity: 1;
	}

	&.alwaysVisible {
		opacity: 1;
		pointer-events: all;
	}
}

body:global(.native) {
	.root {
		top: var(--native-top-adjust);
	}
}

