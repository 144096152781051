@import 'shared/styles/common.scss';

.root {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	--background: white;
	--content-color: black;

	&.darkMode {
		--content-color: white;
		--background: rgba(0,0,0,0.5);
	}

	background: var(--background);
	color: var(--content-color);
	
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: auto;

	* { 
		box-sizing: border-box;
	}

	padding: 1rem;

	.partnerLogo,
	.content,
	.ctaButtons {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		width: 100%;

		max-width: 400px;
	}

	.partnerLogo {
		width: auto;

		background: white;
		padding: 1rem 3rem .25rem;
		width: calc(100% - 2rem);
		// margin: 0 1rem;
			
		border-radius: .25rem;
	}

	&.darkMode {
		.partnerLogo {
			padding: 1.25rem 3rem 1.5rem;
		}
	}

	.masthead {
		margin: 0;
		padding: 1.5rem 0 .75rem;
		text-align: center;
		line-height: 1.1;
		font-size: 1.5rem;
	}

	&.darkMode {
		:global(.MuiFormControl-root) {
			// background: $highlight-color;
			background: transparentize($highlight-color, 0.75);

			:global(.MuiInputLabel-formControl) {
				color: rgba(255,255,255,0.75);

				&:global(.Mui-focused) {
					color: white;
				}
			}
		}
	}
	
	.ctaButtons {
		padding: 1.25rem .5rem 0;

		:global(.AppButton-root) {
			padding: .9rem 3rem;

			svg {
				margin-right: .5rem;
			}
		}
	}

	&.darkMode {
		$content-color: white;

		:global(.MuiFormLabel-root),
		:global(.MuiSelect-icon) {
			// color: rgba(255,255,255,0.54) !important;
			color: transparentize($content-color, 0.44);
		}

		:global(.MuiInput-root),
		:global(.MuiIconButton-root),
		:global(.MuiButton-root) {
			color: $content-color !important;
		}

		:global(.MuiIconButton-root):global(.MuiButton-textPrimary),
		:global(.MuiButton-root):global(.MuiButton-textPrimary) {
			color: darken($highlight-color, 1%) !important;
		}

		:global(.MuiInput-underline):hover:not(:global(.Mui-disabled)):before,
		:global(.MuiInput-underline:before) {
			// border-color: rgba(255,255,255,0.47) !important;
			border-color: transparentize($content-color, 0.53) !important;
		}

		:global(.MuiTypography-colorTextSecondary) {
			// color: rgba(255,255,255,0.77) !important;
			color: transparentize($content-color, 0.33) !important;
		}
	}
}